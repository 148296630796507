﻿"use strict";

(function () {
    var DATA_FIELD_STATUS = "field-status",
        FIELD_STATUS_MANDATORY = "Mandatory";

    ori.addModule("validation", {
        isFieldMandatory: function ($input) {
            return $input.data(DATA_FIELD_STATUS) === FIELD_STATUS_MANDATORY;
        }
    });
})(jQuery);